// extracted by mini-css-extract-plugin
export var column = "UnderscoreFancy__column__U0X7A";
export var flex = "UnderscoreFancy__flex__xWa5D";
export var flexColumn = "UnderscoreFancy__flexColumn__FJ1Yt";
export var gap1 = "UnderscoreFancy__gap1__BVUq0";
export var gap2 = "UnderscoreFancy__gap2__tlBNp";
export var gap3 = "UnderscoreFancy__gap3__ZG7Bp";
export var gap4 = "UnderscoreFancy__gap4__BwsU9";
export var gap5 = "UnderscoreFancy__gap5__HuRvV";
export var orange = "UnderscoreFancy__orange__CGyi8";
export var red = "UnderscoreFancy__red__YwNW1";
export var row = "UnderscoreFancy__row__ijLQh";
export var teal = "UnderscoreFancy__teal__k3Arx";
export var underscoreTriangle = "UnderscoreFancy__underscoreTriangle__UuXAd";
export var underscoreTriangleWrapper = "UnderscoreFancy__underscoreTriangleWrapper__C9DYs";